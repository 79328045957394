<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="pro-list">
        <div class="cardRow" v-for="i in Math.ceil(dataList.length/2)" :key="'row'+i">
          <div class="item" v-for="(item,j) in dataList.slice((i-1)*2,i*2)" :key="item.id"  @click="handleDetailClick(item.id)">
            <div class="type">{{item.subsidyName}}</div>
            <div class="product">
              <img :src="item.pictureUrl" class="img" />
              <div class="info">
                <div class="desc-item">
                  <label class="info-name">最高补贴金额：</label><label class="info-value">{{item.maxAmount}}万</label>
                </div>
                <div class="desc-item">
                  <label class="info-name">补贴对象：</label><label class="info-value">{{item.subsidyObject}}</label>
                </div>
                <div class="desc-item">
                  <label class="info-name">机构名称：</label><label class="info-value">{{item.institutionName}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'perkList',
  components: {
    TopFilter
  },
  data() {
    return {
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      current: 0,
      keyword: "",
    }
  },
  computed:{
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.technologyFinance.subsidyList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({path:"/technologyFinance/perkDetail",  query: {id: id}});
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .cardRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 48%;
      height: 200px;
      box-sizing: border-box;
      border: 1px solid #ECECEC;
      border-top: 5px solid burlywood;
      padding: 10px 0;
      margin: 30px 0;
      .type {
        width: 90%;
        text-align: center;
        font-weight: 700;
        padding: 10px 20px;
        border-bottom: 2px dotted #ECECEC;
      }
      .product {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .img {
          //width: 100px;
          height: 80px;
          padding-left: 20px;
        }
        .info {
          flex: 0.7;
          height: 80%;
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .desc-item{
            display: flex;
            flex-direction: row;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .info-name{
              font-size: 12px;
              font-weight: 200;
            }
            .info-value{
              font-size: 12px;
              width: 80%;
              flex: 0.5;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .more {
          display: inline-block;
          background-color: #0046C0;
          color: #fff;
          padding: 8px 15px;
          border-radius: 6px;
          margin: 10px 20px 20px;
          cursor: pointer;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046C0;
    }
  }
}
</style>
