<template>
  <div class="filter-box">
    <div class="first-type" v-for="(first, index) in typeList" :key="index">
      <div class="type-item">
        <div class="left">{{first.name}}</div>
        <div class="right">
          <div
            v-for="(keywords, i) in first.val"
            :key="i"
            :class="{'key-item': true, 'active': first.current == keywords.val}"
            @click="handleItemClick(first, keywords.val, first.code)"
          >{{keywords.name}}</div>
        </div>
      </div>
    </div>
    <div class="first-type" v-show="where">
      <div class="type-item">
        <div class="left">地域</div>
        <div class="right">
          <el-select
            size="small"
            class="key-item"
            v-model="form.value"
            filterable
            placeholder="试试搜索：绵阳"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="sort-list">
      <div class="left">综合排序</div>
      <div class="right">
        <div class="key-item" v-for="(item, i) in sortConfig" :key="i">
          {{item.name}}
          <span class="caret-wrapper">
            <i
              class="sort-caret ascending"
              :style="{'--backColor' : sortSelect[i]==1?'blue':'grey'}"
              @click="changeSort(item,i,'asc')"
            ></i>
            <i
              class="sort-caret descending"
              :style="{'--backColor' : sortSelect[i]==0?'blue':'grey'}"
              @click="changeSort(item,i,'desc')"
            ></i>
          </span>
        </div>
        <div class="form-item">
          <div class="search">
            <el-input
              placeholder="请输检索关键字"
              prefix-icon="el-icon-search"
              v-model="form.keywords"
              @change="handleKeyWordChange"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <img style="margin-top: 20px" src="../../../static/line.png" height="2" width="100%" />
  </div>
</template>

<script>
export default {
  name: 'topFilter',
  props: {
    typeList: [],
    sortConfig: [],
    where: false
  },
  data() {
    return {
      form: { keywords: '' },
      sortSelect: [],
      options: [{
        value: '1',
        label: '绵阳'
      }, {
        value: '2',
        label: '成都'
      }, {
        value: '3',
        label: '攀枝花'
      }],
    }
  },
  computed: {
  },
  mounted() {

  },
  watch: {
    sortConfig: {
      handler(val) {
        for (let i = 0; i < val.length; i++) {
          this.sortSelect.splice(i, 0, 2);
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    handleKeyWordChange() {
      this.$emit('handleSearchParams', this.form)
    },
    changeSort(item, index, position) {
      this.sortSelect.splice(index, 1, position == 'asc' ? 1 : 0);
      this.form.orderBy = item.code
      this.form.orderType = position
      this.$emit('handleSearchParams', this.form)
    },
    handleItemClick(type, val, code) {
      type.current = val
      this.form[code] = val
      this.$emit('handleSearchParams', this.form)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.filter-box {
  font-size: 18px;
  padding-top: 20px;
  .type-item {
    display: flex;
    line-height: 32px;
    .left {
      font-weight: 700;
      margin-right: 40px;
      white-space: nowrap;
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      .key-item {
        padding: 0 15px;
        margin: 0 60px 30px 0;
        border-radius: 6px;
        cursor: pointer;
      }
      .key-item.active {
        background-color: #0046c0;
        color: #fff;
      }
    }
  }

  .sort-list {
    display: flex;
    .left {
      height: 40px;
      line-height: 40px;
      font-weight: 700;
      margin-right: 30px;
      white-space: nowrap;
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .key-item {
        padding: 0 15px;
        margin: 0 20px 0 0;
        border-radius: 6px;
        cursor: pointer;
      }
      .caret-wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 34px;
        vertical-align: middle;
        cursor: pointer;
        overflow: initial;
        position: relative;
        .sort-caret {
          width: 0;
          height: 0;
          border: solid 5px transparent;
          position: absolute;
          left: 5px;
        }
        .sort-caret.ascending {
          border-bottom-color: var(--backColor);
          top: -15px;
        }
        .sort-caret.descending {
          border-top-color: var(--backColor);
        }
      }
      .form-item {
        flex: 1;
        .search {
          float: right;
          width: 300px;
        }
      }
    }
  }
}
</style>
